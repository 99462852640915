// export const orderSettle = {
//     'drinksAmount': {
//         required: true,
//         description: '请填写酒水金额',
//         regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
//         regExpMsg:'请填写正确酒水金额'
//     }, 
//     'amount': {
//         required: true,
//         description: '请填写消费金额',
//         regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
//         regExpMsg:'请填写正确消费金额'
//     },            
//     'qty': {
//         required: true,
//         description: '请填写正确人数',
//         regExp:/^\+?[1-9][0-9]*$/,
//         regExpMsg:'请填写正确人数'            
//     },       
//     'hasFiles': {
//         required: true,
//         description: '请上传至少一张水单'
//     }
// }
export const selectCity = {
    'province': {
        required: true,
        description: '请选择省份'          
    },
    'city': {
        required: true,
        description: '请选择城市'          
    },    
}