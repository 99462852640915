//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PopupFullPicker from "../../components/qshop/popup-full-picker";
import selectInfo from "../../components/qshop/selectInfo.js";
import { selectCity as validator } from "../../components/validatorConfig/mealValidatorConfig";
import { mapMutations } from "vuex";
export default {
  extends: selectInfo,
  data() {
    return {
      results: null,
      showPicker: false,
      mealQueryType: "",
      oldCity: ""
    };
  },
  created() {
    let mealQueryType = this.$route.query.mealQueryType;
    this.mealQueryType = mealQueryType;
    this.iStorage.set("mealQueryType", String(this.mealQueryType));
    if (this.mealQueryType == 1) {
      this.oldCity = this.takeoutCity;
    }
    if (this.mealQueryType == 2) {
      this.oldCity = this.mealCity;
    }
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "setCityDictVal",
      "setMealParams",
      "setTakeoutParams",
      "setTab"
    ]),
    nextHandle() {
      this.initProvinceCity();
      var input = {
        province: this.province,
        city: this.city
      };
      const validateResult = this.validate(input, validator);
      if (!validateResult) return;
      if (this.mealQueryType == 1) {
        this.setTakeoutParams({ key: "TakeoutCityDictVal", value: this.city });
        if (this.oldCity && this.oldCity != this.city) {
          let array = ["address", "selectDate", "time"];
          array.map(item => {
            this.setTakeoutParams({ key: item, value: "" });
          });
        }
      }
      if (this.mealQueryType == 2) {
        this.setMealParams({ key: "MealCityDictVal", value: this.city });
        this.setMealParams({
          key: "MealProvinceDictVal",
          value: this.province
        });
        this.setMealParams({ key: "MealCityGPS", value: this.gps });
      }
      let backRoute = this.$route.query.backRoute;
      this.$router.push({ path: backRoute });
    },
    async getProvince() {
      var params = {
        keyCode: "CPC-Province",
        lnkDictVal: "中国"
      };
      await this.QueryDoCityPromise(params);
    }
  },
  components: {
    PopupFullPicker
  }
};
